/* // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */

body {
  background-color: #fafafa;
}

h4 {
  margin-top: 25px;
}

hr {
  margin-top: 40px;
  margin-bottom: 40px;
}

.container {
  background-color: white;
}

#adb-container {
  align-content: center;
  margin: auto;
  min-width: 582px;
  max-width: 1200px;
}

.page-header .adb-logo-wrapper {
  display: flex;
  margin: 0;
}

.page-header .logosLeft {
  flex: 0 0 50%;
  margin: 0;
}

.page-header .logosRight {
  flex: 1;
  display: block;
  margin: 0;
}

.page-content {
  display: flex;
  flex-wrap: wrap;
}

.about {
  border: 1px solid #eaeaea;
  background-color: white;
  padding: 2em;
}

.adb-sensor-chart,
.adb-double-chart-container,
.electric-board {
  background-color: white;
  margin-bottom: 20px;
}

.adb-sensor-chart {
  position: relative;
  height: 300px;
  min-width: 580px;
  padding: 5px 11px 45px 11px;
  border: 1px solid #eaeaea;
}

.adb-double-chart-container .adb-sensor-chart {
  border: 0;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 40px;
}

.adb-sensor-chart:nth-child(even) {
  margin-right: 0px;
}

.adb-sensor-chart h4 {
  text-align: center;
  font-size: 0.95em;
  position: relative;
}

.adb-sensor-chart .chartLastValueBox {
  background-color: #fafafa;
  color: gray;
  width: 100px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 30px;

  font-size: 14px;
  font-weight: bold;
}

.adb-sensor-chart .chartCommunicationProtocol {
  color: silver;
  text-align: center;
  position: absolute;
  left: 75px;
  top: 56px;

  font-size: 8px;
  margin: 0 auto;
}

.adb-sensor-chart .datePicker {
  text-align: center;
  position: absolute;
  right: 10px;
  top: 25px;
  margin: 0 auto;
}

.adb-sensor-chart .datePicker input{
  text-align: center;
  width: 70px;
  font-size: 0.8em;
}

.adb-sensor-chart .datePicker > button {
    font-size: 0.6em;
    padding: 2px 5px 2px 5px;
    position: relative;
    top: -2px;
    margin: 0 2px 0 2px;
} 

.adb-sensor-chart .no-data-place-holder {

  position: relative;
  height: 220px;
  min-width: 500px;
  padding: 5px 11px 45px 11px;
  /* margin: 0 auto; */
  margin: 0 0 0 60px;
  text-align: center;
  line-height: 220px;
  font-size: 1.6em;
  background-color: silver;
}

.adb-sensor-chart .chartHistoricalRealtimeToggles {
    position: absolute;
    left: 80px;
    top: 30px;
    font-size: 12px;
    font-size: 0.7em;
}


.adb-sensor-chart .chartChargingStatus {
  color: #343818;

  text-align: center;
  position: absolute;
  right: 30px;
  top: 10px;

  font-size: 12px;
}

.adb-double-chart-container {
  min-width: 580px;
  height: 810px;
  border: 1px solid #eaeaea;
}

 #outside-temperature-sensor {
  text-align: right;
  margin: 0;
  padding: 15px;
  color: gray;
  font-size: 0.9em;
} 

.adb-tooltip {
  text-align: center;
  font-size: 11px;
  width: 50px;
  background-color: #edeecf;
  border: 1px solid #bbbbbb;
  padding: 0.5em 0 0.5em 0;
  min-width: 80px;
}

.adb-tooltip strong {
  font-size: 14px;
}

.electric-board {
  min-width: 580px;
  height: 810px;
  background-color: white;
  border: 1px solid #eaeaea;
}

.page-footer {
  margin: 5px 0 5px 0;
  padding: 10px;
}

.imgloader {
  width: 200px;
  height: 200px;
  margin-left: auto;
}

.error-box {
  font-weight: bold;
  margin: 1em;
  font-size: 1.4em;
  color: red;
}

@media screen and (max-width: 740px) {

  .page-header {
    background-color: white;
    margin: 5px 0 5px 0;
    padding: 5px;
    border: 1px solid #eaeaea;
    /* height: 160px; */
  }

  .page-header h1 {
    font-size: 1.5em;
    margin-top: 10px;
  }

  img.adbitech {
    width: 85px;
  }

  img.he3da {
    width: 95px;
    margin-right: 5px;
  }

  img.powerorbital {
    width: 85px;
    margin-right: 5px;
  }

  .page-header p {
    margin: 0 0 0 0;
  }

  .page-header .adb-links-bar a {
    margin: 0 0.5em 0 0;
  }

  .page-header p.App-clock {
    font-size: 0.7em;
  }

}

@media screen and (min-width: 740px) {

  .page-header {
    background-color: white;
    margin: 10px 0 10px 0;
    padding: 10px;
    border: 1px solid #eaeaea;
    /* height: 160px; */
  }

  .page-header h1 {
    font-size: 2em;
  }

  img.adbitech {
    width: 110px;
  }

  img.he3da {
    width: 130px;
  }

  img.powerorbital {
    width: 120px;
  }

  .page-header .logosRight p {
    margin-bottom: 0;
    margin-top: 1em;
  }

  .page-header .adb-links-bar {
    margin-top: 1em;
  }

  .page-header .adb-links-bar a {
    margin: 0 0 0 1em;
    font-size: 1.1em;
  }
  
  .page-header p.App-clock {
    font-size: 1em;
  }
}

@media screen and (min-width: 1200px) {

  .page-header {
    position: relative;
    background-color: white;
    margin: 20px 0 20px 0;
    padding: 10px;
    border: 1px solid #eaeaea;
    height: 160px;
  }

  .page-header h1 {
    font-weight: 600;
    font-size: 3em;
  }

  .page-header .adb-links-bar {
    margin-top: 40px;
  }

  img.adbitech {
    width: 150px;
  }

  img.he3da {
    width: 230px;
  }

  img.powerorbital {
    width: 190px;
    margin-right: 10px;
  }

  .page-header p {
    margin: 1em 0 0 0;
  }

  .page-header .currentTime {
    text-align: right;
    margin: 0;
  }

  .page-header .logosRight img {
    margin-top: 10px;
  }

  .page-header .logosRight p {
    margin-top: 40px;
  }

  .adb-sensor-chart,
  .adb-double-chart-container,
  .electric-board {
    min-width: 585px;
  }

  .adb-double-chart-container .adb-sensor-chart {
    min-width: 570px;
  }

}


.grid-container {
  display: grid;
  grid-template-columns: 400px 400px;
  grid-gap: 20px;
  margin: 0 auto;
}

.grid-item {
  /* display: flex; */
  /* background-color: #eee; */
  border-radius: 5px;
  /* padding: 20px; */
  font-size: 150%;
  text-align: center;
}

.datePicker-balance-page .react-datepicker {

  /* width: 100%; */
  font-size: 1.4rem;

}

.datePicker-balance-page .react-datepicker__month-container {

  /* width: 80%; */
  
}

.datePicker-balance-page .react-datepicker__week {

  width: 100%;

}

.datePicker-balance-page .react-datepicker__day, .datePicker-balance-page .react-datepicker__day-name {

  margin: 10px;
}


.datePicker-balance-page .react-datepicker__current-month, .datePicker-balance-page .react-datepicker-time__header, .datePicker-balance-page .react-datepicker-year-header {

  font-size: 1.5rem;
}

.datePicker-balance-page .react-datepicker__time-list {

  height: 100px;
}

.grid-container .full-width {
  /* grid-row:    2; */
  grid-column: 1 / 3;
}

.loader {
  margin: 50px auto;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.energyData {

  margin: 50px;
}



